angular.module("adminApp")
	.factory('createNewSystemPropertyModal', ["$uibModal", function ($uibModal) {

		function open(property, types) {
			var modal = $uibModal.open({
                size: "md",
				templateUrl: "/static/templates/admin/modals/createNewSystemPropertyModal.html",
				resolve: {
					property: function () { return property; },
					types: function() { return types;}
				},
				controller: "CreateNewSystemPropertyModalCtrl"
			});
			return modal.result;
		}

		return {
			open: open
		};
	}])
	.controller("CreateNewSystemPropertyModalCtrl", [ "$scope", "$uibModalInstance", "property", "types", function($scope, $uibModalInstance, property, types) {
		$scope.property = {};
		$scope.types = types;
		if (property) {
			$scope.property.type = property.type;
			$scope.property.value = property.value;
			$scope.isEdit = true;
		}

		$scope.cancel = function() {
			$uibModalInstance.dismiss();
		};

		$scope.save = function() {
			$uibModalInstance.close($scope.property);
		};
	}]);
